import {buildQuery} from 'axios-graphql-builder'

export const myTradeBookQuery = () => {
	const marginTrades = {
		allInRate: null,
		cashIndicator: null,
		creditRating: null,
		context: {
			displayStatus: null,
		},
		collateralReceiver: null,
		collateralProvider: null,
		counterParties: null,
		counterPartyLegalEntityId: null,
		direction: null,
		indemnificationId: null,
		notional: {
			amount: null,
			currency: null,
		},
		notionalLimit: {
			amount: null,
			currency: null,
		},
		orderId: null,
		orderIndicatorType: null,
		orderResponseId: null,
		orderStage: null,
		orderType: null,
		ownerIndicator: null,
		tctr: null,
		term: null,
		time: null,
		tradeStatus: null,
		displayTime: null,
		indicator: null,
		cusip: null,
		schedule: null,
		collateralType: null,
		tradeDate: null,
		settlementDate: null,
		maturityDate: null,
		settlementId: null,
		settlementMargin: null,
		settlementMarginStatus: null,
		terminationDate: null,
		transactionMarginPercentage: null,
		maturityType: null,
		margin: null,
		createdBy: null,
		dirtyPrice: {
			amount: null,
		},
		startCash: {
			amount: null,
		},
		repoInterest: null,
		accruedInterest: null,
		endCash: {
			amount: null,
		},
		quantity: null,
	}

	const openCompletedOrder = {
		query: {
			marginTrades,
		},
	}

	return buildQuery(openCompletedOrder)
}
export const extendableActivityLogQuery = orderId => {
	const paramList = [
		{
			key: 'extendableOrderResponseCollaborationLogs ',
			param: `orderId : "${orderId}"`,
		},
	]

	const activityLog = {
		query: {
			extendableOrderResponseCollaborationLogs: {
				time: null,
				title: null,
				activity: null,
				level: null,
				responseId: null,
			},
		},
	}

	return buildQuery(activityLog, paramList)
}

export const availabilitiesWithScheduleQuery = (schedule: string, book: string = null, clientRiskType = null) => {
	const paramList = [
		{
			key: 'boxesEnrichDetailsForSchedule',
			param: `schedule: ${JSON.stringify(schedule)},book: ${JSON.stringify(book)},clientRiskType: ${JSON.stringify(
				clientRiskType
			)}`,
		},
	]

	const getTemplateByClient = {
		query: {
			boxesEnrichDetailsForSchedule: {
				entries: {
					assetType: null,
					availableQuantity: null,
					securityMarginPercentage: null,
					price: null,
					priceMultiplier: null,
					securityId: null,
					cusip: null,
					schedule: null,
					minPiece: null,
					minIncrement: null,
					minLotSize: null,
				},
			},
		},
	}

	return buildQuery(getTemplateByClient, paramList)
}
