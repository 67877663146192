import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import Lodash from 'lodash'
import {permission} from '../constants/permission'
import {checkFeaturePermission, flattenRow} from '../utils/featureUtils'
import {parseQuery} from '../utils/queryParse'
import {urlSafeDecode} from '@aws-amplify/core'
import {getObjectFromStorage, getStringFromStorage} from '../services/storageService'
import {clearAuthToken, isAuthenticated} from '../services/authService'
import {paths} from '../routes/routesConfig'
import {initializeAmplify, redirectLoginWithTenantCode} from '../utils/amplify'
import {setAuthExpiryTime} from '../utils/auth'
import {mainStore} from '../store/mainStore'
import {resetCounterparty} from '../pages/PeerToPeer/OrderEntryModal/OrderEntryModalAction'

export const setMainContentWidth = memoizeOne(isWide => {
	const mainContentEl = document.getElementById('main-content')
	if (mainContentEl) {
		mainContentEl.style.paddingLeft = isWide ? '235px' : '60px'
		mainContentEl.style.transition = 'all 0.3s'
	}
}, isDeepEqual)

export const formatPortfolioAllEntries = data => {
	const entries = {}
	Array.isArray(data) &&
		data.forEach(item => {
			entries[item.id] = flattenRow(item)
		})
	return entries
}

export const addNewEntries = (existingEntry: any, newEntries: any[]) => {
	const entries = JSON.parse(JSON.stringify(existingEntry))
	newEntries.forEach(item => {
		entries[item.id] = flattenRow(item)
	})
	return entries
}

export const sumOfNotifications = notifications => {
	const data = Lodash.cloneDeep(notifications)
	let notificationCount: any = Object.values(data).reduce((a: number, b: number) => a + b, 0)
	if (data['total']) notificationCount -= data['total']
	return notificationCount
}

export const isReadWritePortfolioPermission = () => {
	return checkFeaturePermission(permission.READ_PORTFOLIO) || checkFeaturePermission(permission.WRITE_PORTFOLIO)
}

export const isRestrictedEntryPermission = () => {
	return (
		checkFeaturePermission(permission.READ_RESTRICTED_SECURITY_DOCUMENT) ||
		checkFeaturePermission(permission.WRITE_RESTRICTED_SECURITY_DOCUMENT)
	)
}

export const checkConfigDetailsInStorage = () => {
	const query: any = parseQuery(window.location.search)
	let tenantCode
	if (query.state) {
		const isCustomStateIncluded = /-/.test(query.state)
		if (isCustomStateIncluded) {
			const customState: string = query.state.split('-').splice(1).join('-')
			tenantCode = urlSafeDecode(customState)
		}
	} else if (query.tenant_code) {
		tenantCode = query.tenant_code
	}
	const tenant = getStringFromStorage('tenant')

	let amplifyConfig = {}
	if (tenantCode === tenant || isAuthenticated()) {
		amplifyConfig = getObjectFromStorage('amplifyConfig')
	}
	return {amplifyConfig, tenant}
}

export const maskLoginErrorMessages = errObject => {
	const {code, mode, message} = errObject
	if (mode === 'Login' || mode === 'Reset') {
		if (['UserNotFoundException', 'UserNotConfirmedException', 'InvalidParameterException'].includes(code)) {
			return `Your username ${mode !== 'Reset' ? 'or password' : ''} is incorrect. Please check and try again.`
		} else if (code === 'NotAuthorizedException') {
			if (mode === 'NewPassword' || mode === 'Reset') {
				return 'Your password cannot be reset in the current state. Please contact your administrator.'
			} else if (mode === 'Login') {
				return 'Your username or password is incorrect. Please check and try again.'
			}
			return 'The application has encountered an unknown error. Please contact your system administrator.'
		}
	}
	return mode === 'Login'
		? 'The application has encountered an unknown error. Please contact your system administrator.'
		: message
}

export const genericLogout = (authLogout = null, history = null) => {
	mainStore.dispatch(resetCounterparty())
	if (authLogout && isAuthenticated()) {
		authLogout().then(() => {
			clearAuthToken()
			history && redirectLoginWithTenantCode(history)
		})
	} else {
		clearAuthToken()
		history && redirectLoginWithTenantCode(history)
	}
}

export const authenticateRoutesAndLogout = history => {
	const {location: {pathname: activePath} = {pathname: ''}} = history
	if (!activePath.includes(paths.login) && activePath !== paths.default) {
		if (!isAuthenticated()) {
			genericLogout(null, history)
		}
	}
}

export const setAuthExpiryTimeFn = (history, token = null, authLogout = null) => {
	if (isAuthenticated()) {
		setAuthExpiryTime(token, history, (resolved, token) => {
			if (!resolved) {
				genericLogout(authLogout, history)
			}
		})
	} else {
		genericLogout(null, history)
	}
}

export const handleBrowserRefreshCognitoActivity = (history, authLogout: Function) => {
	const {location: {pathname: activePath} = {pathname: ''}} = history
	if (!activePath.includes(paths.login) && activePath !== paths.default) {
		if (isAuthenticated()) {
			const amplifyConfig = getObjectFromStorage('amplifyConfig')
			const tenant = getStringFromStorage('tenant')
			initializeAmplify(amplifyConfig, tenant, false)
			setAuthExpiryTimeFn(history, null, authLogout)
		} else {
			authenticateRoutesAndLogout(history)
		}
	}
}
