import {createFetchActions} from '../../../services/createActions'
import {getCollateralBasketQuery, getCounterpartyQuery, typeAheadResultQuery} from './OrderEntryModalQuery'
import {getConfigGraphBaseUrl, getPMSGraphBaseUrl, getConfigBaseUrl} from '../../../services/apiService'
import {getMDSBaseUrl, getMPSBaseUrl, getPMSBaseUrl} from '../../../services/apiService'
import {get, graphQlWithAxios, post, put} from '../../../services/createAPICall'

export const CREATE_ORDER_ENTRY = createFetchActions('CREATE_ORDER_ENTRY')
export const GET_BENCHMARK = createFetchActions('GET_BENCHMARK')
export const GET_BENCHMARKSTT = createFetchActions('GET_BENCHMARKSTT')
export const GET_COUNTERPARTY = createFetchActions('GET_COUNTERPARTY')
export const GET_HOLIDAYS = createFetchActions('GET_HOLIDAYS')
export const GET_SCHEDULE = createFetchActions('GET_SCHEDULE')
export const GET_COLLATERAL_BASKET = createFetchActions('GET_COLLATERAL_BASKET')
export const GET_TYPE_AHEAD_RESULT = createFetchActions('GET_TYPE_AHEAD_RESULT')
export const UPDATE_ORDER_ENTRY = createFetchActions('UPDATE_ORDER_ENTRY')
export const VALIDATE_SCHEDULE = createFetchActions('VALIDATE_SCHEDULE')
export const UPLOAD_COLLATERAL_BASKET = createFetchActions('UPLOAD_COLLATERAL_BASKET')
export const CUSIP_SCHEDULE = createFetchActions('CUSIP_SCHEDULE')
export const GET_SCHEDULE_COLLATERAL_BASKET = createFetchActions('GET_SCHEDULE_COLLATERAL_BASKET')
export const RESET_HOLIDAYS = 'RESET_HOLIDAYS'
export const RESET_COUNTERPARTY = 'RESET_COUNTERPARTY'

export const createOrderEntry = data => (dispatch: any) => {
	return dispatch(post(CREATE_ORDER_ENTRY, `${getPMSBaseUrl()}/v1/orders`, data))
}

export const getCounterparty = () => {
	const query = getCounterpartyQuery()
	const baseURL = `${getConfigGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_COUNTERPARTY, baseURL, query, response => {
			dispatch({
				type: GET_COUNTERPARTY.succeeded,
				response: response && response.data && response.data.data ? response.data.data.allClients : [],
			})
		})
	}
}

export const getHolidays = () => (dispatch: any) =>
	dispatch(get(GET_HOLIDAYS, `${getConfigBaseUrl()}/v1/holidays/US/FED`))

export const getCollateralBasket = () => {
	const query = getCollateralBasketQuery()
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_COLLATERAL_BASKET, baseURL, query, response => {
			dispatch({
				type: GET_COLLATERAL_BASKET.succeeded,
				response: response && response.data && response.data.data ? response.data.data.openCollateralBaskets : [],
			})
		})
	}
}

export const getScheduleOption = () => (dispatch: any) => {
	return dispatch(get(GET_SCHEDULE, `${getPMSBaseUrl()}/v1/cusip-schedule`))
}

export const getBenchmark = () => dispatch => {
	return dispatch(get(GET_BENCHMARK, `${getMPSBaseUrl()}/v1/floating-values`))
}

export const getBenchmarkSTT = () => dispatch => {
	return dispatch(get(GET_BENCHMARKSTT, `${getMPSBaseUrl()}/v1/mps/orders/benchmarks`))
}

export const getTypeAheadResult = (keyword, legalEntityId, clientRiskType, isBidCollateralOffer) => {
	const query = typeAheadResultQuery(keyword, legalEntityId, clientRiskType, isBidCollateralOffer)
	const baseURL = `${getPMSGraphBaseUrl()}`
	return function (dispatch) {
		graphQlWithAxios(dispatch, GET_TYPE_AHEAD_RESULT, baseURL, query, response => {
			const securityDataArray =
				response && response.data && response.data.data ? response.data.data.securitySuggestions : []
			if (securityDataArray.length > 1)
				securityDataArray.sort((a, b) => (a.availableQuantity < b.availableQuantity ? 1 : -1))

			dispatch({
				type: GET_TYPE_AHEAD_RESULT.succeeded,
				response: securityDataArray,
			})
		})
	}
}

export const updateOrderEntry = (id, data) => (dispatch: any) => {
	return dispatch(put(UPDATE_ORDER_ENTRY, `${getPMSBaseUrl()}/v1/orders/staging/${id}`, data))
}

export const getCusipSchedule: any = securityId => (dispatch: any) => {
	return dispatch(get(CUSIP_SCHEDULE, `${getMDSBaseUrl()}/v1/mds/cusip-schedule/${securityId}`))
}

export const validateSchedule = (data: any) => (dispatch: any) => {
	if (!data) return dispatch({type: VALIDATE_SCHEDULE.triggered})

	return dispatch(post(VALIDATE_SCHEDULE, `${getPMSBaseUrl()}/v1/cusip-schedule/validate`, data))
}

export const getSchedule: any = (data: any) => (dispatch: any) => {
	return dispatch(get(GET_SCHEDULE_COLLATERAL_BASKET, `${getPMSBaseUrl()}/v1/collateral-baskets/${data}/schedule`))
}

export const uploadCollateralBasket: any = (data: any) => (dispatch: any) => {
	if (!data) return dispatch({type: UPLOAD_COLLATERAL_BASKET.triggered})

	return dispatch(post(UPLOAD_COLLATERAL_BASKET, `${getPMSBaseUrl()}/v1/collateral-baskets/upload`, data))
}

export const getCusipScheduleOfSecurity = securityId => (dispatch: any) => {
	return dispatch(get(CUSIP_SCHEDULE, `${getMDSBaseUrl()}/v1/mds/cusip-schedule/${securityId}`))
}

export const resetHolidayCalender = () => {
	return {
		type: RESET_HOLIDAYS,
	}
}

export const resetCounterparty = () => {
	return {
		type: RESET_COUNTERPARTY,
	}
}
