import {createFetchActions} from '../../../services/createActions'
import {del, get, post, put} from '../../../services/createAPICall'
import {getConfigBaseUrl} from '../../../services/apiService'
import {getConfigClient, getMDSClient} from '../../..'
import {getMyLegalEntitiesQuery, sectorsIndicesQuery} from './myLegalEntitiesQueries'
import {getCounterpartyId} from '../../../services/authService'

export const GET_MY_LEGAL_ENTITIES = createFetchActions('GET_MY_LEGAL_ENTITIES')
export const SAVE_MY_LEGAL_ENTITIES = createFetchActions('SAVE_MY_LEGAL_ENTITIES')
export const EDIT_MY_LEGAL_ENTITIES = createFetchActions('EDIT_MY_LEGAL_ENTITIES')
export const GET_SECTORS_INDICES = createFetchActions('GET_SECTORS_INDICES')
export const SEARCH_LEGAL_ENTITY = createFetchActions('SEARCH_LEGAL_ENTITY')
export const SEARCH_LEGAL_ENTITY_DETAIL = createFetchActions('SEARCH_LEGAL_ENTITY_DETAIL')
export const CLEAR_ALL_LEGAL_ENTITY = createFetchActions('CLEAR_ALL_LEGAL_ENTITY')
export const SET_SELECTED_LEGAL_SEARCH = createFetchActions('SET_SELECTED_LEGAL_SEARCH')
export const CLEAR_LEGAL_ENTITY_DATA = createFetchActions('CLEAR_LEGAL_ENTITY_DATA')
export const DELETE_LEGAL_ENTITY = createFetchActions('DELETE_LEGAL_ENTITY')

export const getMyLegalEntities = () => {
	return function (dispatch) {
		dispatch({type: GET_MY_LEGAL_ENTITIES.triggered})
		getConfigClient()
			.query({
				query: getMyLegalEntitiesQuery(getCounterpartyId()),
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_MY_LEGAL_ENTITIES.succeeded,
					response,
				})
			})
	}
}

export const saveMyLegalEntities = (data: any) => (dispatch: any) => {
	return dispatch(
		post(SAVE_MY_LEGAL_ENTITIES, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/legalentities`, data)
	)
}

export const editMyLegalEntities = (data: any) => (dispatch: any) => {
	return dispatch(
		put(
			EDIT_MY_LEGAL_ENTITIES,
			`${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/legalentities/${data.legalEntityId}`,
			data
		)
	)
}

export const deleteLegalEntity = (id: any) => (dispatch: any) => {
	return dispatch(
		del(DELETE_LEGAL_ENTITY, `${getConfigBaseUrl()}/v2/config/tenants/${getCounterpartyId()}/legal_entity/${id}`)
	)
}

export const getSectorsIndices = () => {
	return function (dispatch) {
		dispatch({type: GET_SECTORS_INDICES.triggered})
		getMDSClient()
			.query({
				query: sectorsIndicesQuery,
				fetchPolicy: 'network-only',
			})
			.then(response => {
				dispatch({
					type: GET_SECTORS_INDICES.succeeded,
					response,
				})
			})
	}
}

export const searchLegalEntity = (searchText: string) => (dispatch: any) => {
	if (searchText === '') return null
	return dispatch(get(SEARCH_LEGAL_ENTITY, `${getConfigBaseUrl()}/v2/config/legal-entity/search?name=${searchText}`))
}

export const searchLegalEntityDetail = (id: string) => (dispatch: any) => {
	return dispatch(get(SEARCH_LEGAL_ENTITY_DETAIL, `${getConfigBaseUrl()}/v2/config/legal-entity/details/${id}`))
}

export const clearSearchedLegalEntityData = () => {
	return {type: CLEAR_ALL_LEGAL_ENTITY}
}

export const clearLegalEntityData = () => {
	return {type: CLEAR_LEGAL_ENTITY_DATA}
}

export const setSelectedLegalSearch = details => {
	return {type: SET_SELECTED_LEGAL_SEARCH, payload: details}
}
